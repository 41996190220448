import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsImage from "../image-components/projects"
import ContactTeaser from "../components/contact-teaser"

export default function Template({
  data: {
    allMarkdownRemark: { edges },
  },
  pageContext: {
    totalCount
  },
}) {
  return (
    <Layout>
      <SEO title={'Projects'} description={'Projects and accomplishments of Erik Altman'} keywords={'Erik Altman, projects, technology portfolio, web app developer, javascript'} />
      <ContactTeaser />
      <div className="bg-light">
        <div className="py-4 px-4 bg-primary text-light shadow mb-4">
          <div className="my-4 container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="my-0 px-4 d-flex flex-column align-items-center justify-content-center">
                <div style={{ height: `140px`, width: `140px` }}>
                  <ProjectsImage style={{ height: `220px`, width: `220px` }} />
                </div>
              </div>
              <div className="px-4">
                <h1 className="display-4">Projects</h1>  
                <p className="display-5">These are some of my proudest recent accomplishments.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="container px-4">
            <div className="bg-light py-4">
              <div className="container" style={{ maxWidth: 750 }}>
                {
                  edges
                    .map((edge, i) =>
                      <div className="card my-4 shadow-sm" key={i}>
                        <Img className="card-img-top" style={{ maxWidth: 750 }} fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid} alt="Card image cap" />
                        <div className="card-body">
                          <h3 className="card-title">{edge.node.frontmatter.title}</h3>
                          <p className="card-text">{edge.node.frontmatter.date}</p>
                          <p className="card-text">{edge.node.frontmatter.description}</p>
                          <Link to={edge.node.frontmatter.path} className="btn btn-primary">Read More in the Blog &rarr;</Link>
                        </div>
                      </div>
                    )
                }
                <ul className="pagination justify-content-center mb-4">
                  <li className="page-item disabled">
                    <a className="page-link" href="/">&larr; Newer</a>
                  </li>
                  <li className="page-item disabled">
                    <a className="page-link" href="/">Older &rarr;</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
        filter: {
          frontmatter: {
            tags: {in: ["projects"]}
          }
        },
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            path
            author
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
